import React from 'react';
import { FaLinkedinIn,FaLinkedin } from "react-icons/fa";
// Memoized Execom component
const Execom = React.memo(({ img, name, pos,link }) => {
    return (
        <div className='bg-black'>
            <div className='flex flex-col justify-center items-center'>
                <div className="w-full max-w-xs px-4 py-6 text-center  bg-white rounded-3xl sm:max-w-sm md:w-96 xl:px-10">
                    <div className="space-y-4 xl:space-y-6 ">
                        <img
                            className="mx-auto rounded-full h-28 w-28 sm:h-32 sm:w-32 md:h-36 md:w-36"
                            src={img}
                            alt={`${name} avatar`}
                            loading="lazy"
                        />
                        <div className="space-y-2">
                            <div className="flex justify-center items-center flex-col space-y-3 text-lg font-medium leading-6">
                                <h3 className="text-black font-semibold text-[18px]">{name}</h3>
                                <p className="text-black text-[13px]">{pos}</p>
                                <div className="flex mt-5 space-x-10 ">
                                    {/* Github */}
                                    {/* <a
                                        href="https://github.com/yourprofile"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-block text-gray-400"
                                    >
                                        <span className="sr-only ">GitHub</span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="w-6 h-6 text-gray-400 hover:text-gray-100"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path className='text-black' d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577v-2.045c-3.338.724-4.042-1.416-4.042-1.416-.546-1.387-1.333-1.758-1.333-1.758-1.087-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.834 2.807 1.304 3.492.997.108-.775.418-1.305.762-1.605-2.665-.306-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.235-3.221-.123-.303-.536-1.527.117-3.176 0 0 1.008-.323 3.3 1.23.957-.266 1.98-.398 3.002-.404 1.02.006 2.045.138 3.002.404 2.29-1.554 3.297-1.23 3.297-1.23.655 1.649.242 2.873.12 3.176.77.84 1.234 1.91 1.234 3.221 0 4.609-2.807 5.621-5.479 5.921.43.37.823 1.102.823 2.222v3.293c0 .322.218.694.825.575C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"></path>
                                        </svg>
                                    </a> */}
                                    {/* LinkedIn Icon */}
                                    <a
                                        href={link}
                                        target={link}
                                        rel="noopener noreferrer"
                                        //className="inline-block bg-blue-400 text-white   hover:bg-blue-700 hover:rounded-full transition-colors duration-300"
                                    >
                                        {/* <span className="sr-only">LinkedIn</span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 448 512"
                                            className="w-6 h-6 rounded-full text-gray-400 hover:text-gray-100"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        > */}
                                            <FaLinkedin className='hover:scale-125 text-2xl hover'/>
                                            {/* <path className='text-white rounded-full' d="M100.28 448H7.4V148.9h92.88zm-46.14-345.4C24.3 102.6 0 78.4 0 48A48.12 48.12 0 0 1 48.1 0c26.5 0 48 21.5 48 48a48.1 48.1 0 0 1-48.06 48.6zM447.8 448H355.06V302.4c0-34.6-12.6-58.2-44-58.2-24 0-38.2 16.1-44.5 31.6-2.3 5.5-2.9 13.2-2.9 21v151.2h-92.9s1.2-245.5 0-270.9h92.9v38.4a92.1 92.1 0 0 1 84.1-46.5c61.4 0 107.4 40.5 107.4 127.6V448z"></path> */}
                                        {/* </svg> */}
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Execom;
