import React from 'react';
import {motion} from 'framer-motion'
import { animateScroll } from 'react-scroll';
import AboutUs from './AboutUs';
import { Element, scroller } from 'react-scroll';
function Hero() {
  const textVarient = {
    hidden: { opacity: 0, x: -100 },
    visible: {
        opacity: 1, x: 0,
        transition: {
            duration: 0.2,
            ease: "easeInOut",
        }
    }
}
const textVarient1 = {
  hidden: { opacity: 0, x: 100 },
  visible: {
      opacity: 1, x: 0,
      transition: {
          duration: 0.2,
          ease: "easeInOut",
      }
  }
}
const buttonVarient = {
  hidden: { opacity: 0 },
  visible: {
      opacity: 1,
      transition: {
          duration: 0.8,
          delay: 0.2,
          ease: "easeInOut",
      }
  }
}
const options = {
  // Your options here, for example:
  duration: 500,
  smooth: true,
};
const scrollToSection = () => {
  scroller.scrollTo('aboutSection', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuad',
    offset:-70,
  });
};
  return (
    <div className='w-4/5 mx-auto min-h-[calc(100vh-15rem)] md:min-h-[calc(100vh-9rem)] flex flex-col justify-center items-center text-center space-y-5 ' id='Home'>
      <motion.h1
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
      variants={textVarient}
      className='text-3xl md:text-[48px] font-sans mb-4 font-open-sans text-white'>Welcome to ACM Student Chapter FISAT</motion.h1>
      <motion.p
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
      variants={textVarient1}
      className='text-sm md:text-lg mb-6 font-open-sans text-white'>We are a team united to explore, innovate, and excel</motion.p>
      <motion.button
                    initial="hidden"
                    whileInView="visible"
                    whileHover={{scale:1.08}}
                    whileTap={{scale:0.2}}
                    viewport={{amount:0.5}}
                    variants={buttonVarient}
                    onClick={scrollToSection}
      className='px-6 py-3 bg-transparent border border-white text-[#5aa5f5] text-white rounded-full font-serif'>Get Started</motion.button>
    </div>
  );
}

export default Hero;
