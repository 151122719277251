import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Events() {
    const navigate = useNavigate();
    const textVariant = {
        hidden: { opacity: 0, y: 20, scale: 0.93 },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: "easeInOut",
            },
        },
    };
    const containerVariant = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.2,
            },
        },
    };
    const eventsData = [
        {
            title: "HACKFIT",
            description: "A 36 hour national level coding competition conducted in association with icefoss. It always comes with an alluring prize pool of Rs 50k.",
            icon: "bi-cpu",
        },
        {
            title: "EDGE +",
            description: "Tech-fun event for all students including workshop on Chatbot using Dialogflow, Cultural night and a lot of fun games.",
            icon: "bi-clipboard-data",
        },
        {
            title: "HOUR OF CODE",
            description: "Workshop on basics of Python and HTML for students of Gov. Higher Secondary School, Mookanoor conducted by ACM members.",
            icon: "bi-globe2",
        },
        {
            title: "CELESTIA",
            description: "Annual inter-collegiate quiz competition with prizes worth rupees 1.5K for first place and 1K for second place.",
            icon: "bi-images",
        },
        {
            title: "RENASCENCE",
            description: "One day Tech-fun event for first-year students including workshops on GitHub, Git, and Linux basics along with TechZap, a rapid-fire quiz competition.",
            icon: "bi-sliders",
        },
    ];

    return (
        <div className='bg-[#212529]'>
            <div className='w-4/5 mx-auto flex flex-col justify-center items-center pt-14 text-white'>
                <h1 className='text-3xl font-bold mb-4 font-lato'>Events</h1>
                <p className='mb-6 font-lato text-base'>Exciting tech talks, workshops, and networking opportunities that will inspire and enhance your journey in the world of computer science!</p>


                <motion.div
                    variants={containerVariant}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.3 }}
                    className='flex flex-wrap justify-evenly space-y-2 mt-2 mb-24'
                >
                    {eventsData.map((event, index) => (
                        <motion.div
                            key={index}
                            variants={textVariant}
                            className='group w-full md:w-1/3 p-4 flex flex-col items-center space-y-4'
                        >
                            <i className={`bi ${event.icon} text-4xl group-hover:text-purple-300 transition duration-700`}></i>
                            <h1 className='font-semibold text-2xl group-hover:text-purple-300 transition duration-700 cursor-pointer' onClick={() => navigate('/gallery')}>
                                {event.title}
                            </h1>
                            <p className='text-justify font-light text-sm'>{event.description}</p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
}

export default Events;
