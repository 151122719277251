import React from 'react'
import logo from '../assets/img/ACM FISAT logo2.png'
import { TiTick } from "react-icons/ti";
import {motion} from 'framer-motion'
import { Element, scroller } from 'react-scroll';
<Element name="myScrollToElement"></Element>
function AboutUs() {
    const textVarient = {
        hidden: { opacity: 0, x: -100,delay:10 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.8,
                ease: "easeInOut",
            }
        }
    }
    const textVarient1 = {
        hidden: { opacity: 0, x: 100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.8,
                ease: "easeInOut",
            }
        }
    }
    const buttonVarient = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0,
                ease: "easeInOut",
            }
        }
    }
    return (
        <div className='bg-black flex flex-col items-center overflow-x-hidden md:pb-14' id="about">
            <motion.h1
                initial="hidden"
                whileInView="visible"
                viewport={{amount:0.5}}
                variants={buttonVarient}
                className='text-white font-thin text-4xl md:text-6xl text-center  mt-10 md:mt-14'>About Us</motion.h1>
            <div className='w-4/5 mx-auto flex flex-col items-center space-y-5 mt-12 md:mt-20 text-white md:w-4/5 mx-auto md:flex-row md:justify-evenly'>

                <motion.div 
                initial="hidden"
                whileInView="visible"
                viewport={{amount:0.1}}
                variants={textVarient}
                className='max-w-2xl space-y-7 font-light'>
                    <h1 className='text-2xl'>ACM Student Chapter FISAT</h1>
                    <p>The ACM Student Chapter at FISAT was established with the sole purpose of creating a vibrant and engaging community for students in the Computer
                        Science department.</p>
                    <div className='flex space-x-2'>
                        <TiTick className='w-10' />
                        <p> As an ACM student chapter member, you gain exclusive access to a plethora of opportunities to volunteer, organize events,
                            and participate in various technical and non-technical activities.</p>

                    </div>
                    <div className='flex space-x-2'>
                        <TiTick />
                        <p>Your suggestions and ideas hold great value within our chapter.</p>
                    </div>
                    <div className='flex space-x-2'>
                        <TiTick className='w-10' />
                        <p>From organizing workshops and hackathons to hosting coding competitions and tech talks, we offers a diverse range of events that cater to
                            the interests and aspirations of computer science students.</p>
                    </div>
                    <div className='flex space-x-2'>
                        <TiTick className='w-10' />
                        <p > As a member, you will have the chance to connect and build relationships with your seniors who can provide guidance and
                            support throughout your academic journey.</p>
                    </div>
                </motion.div>
                <motion.img 
                initial="hidden"
                whileInView="visible"
                viewport={{amount:0.5}}
                variants={buttonVarient}
                src={logo} alt="" />
            </div>
        </div>
    )
}
export default AboutUs