import React, { useState, useEffect, useRef } from 'react';
import './modal.css';
import { RiCloseCircleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import p1 from '../assets/img/HoC/IMG_0024.JPG'
import p2 from '../assets/img/HoC/IMG_0315.JPG'
import p3 from '../assets/img/HoC/IMG_0170.JPG'
import p4 from '../assets/img/HoC/IMG_0069.JPG'
const Hoc2 = () => {
  const images = [
    { imageUrl: p1, name: "", description: "" },
    { imageUrl: p2, name: "", description: "" },
    { imageUrl: p3, name: "", description: "" },
    { imageUrl: p4, name: "", description: "" },
  ];
  const navigate=useNavigate()
  const [currentSlide, setCurrentSlide] = useState(0);
  const runningTimeRef = useRef(null);
  const autoNextRef = useRef(null);
  const timeRunning = 3000; 
  const timeAutoNext = 7000; 

  const showSlider = (type) => {
    const sliderItemsDom = document.querySelectorAll('.carousel .list .item');
    
    if (type === 'next') {
      document.querySelector('.list').appendChild(sliderItemsDom[0]);
    } else {
      document.querySelector('.list').prepend(sliderItemsDom[sliderItemsDom.length - 1]);
    }

    clearTimeout(autoNextRef.current);
    resetTimeAnimation();

    autoNextRef.current = setTimeout(() => {
      showSlider('next');
    }, timeAutoNext);
  };

  const resetTimeAnimation = () => {
    const runningTime = document.querySelector('.timeRunning');
    runningTime.style.animation = 'none';
    void runningTime.offsetHeight; 
    runningTime.style.animation = `runningTime ${timeAutoNext / 1000}s linear 1 forwards`;
  };

  const handleNextClick = () => {
    showSlider('next');
  };

  const handlePrevClick = () => {
    showSlider('prev');
  };

  useEffect(() => {
    resetTimeAnimation();
    autoNextRef.current = setTimeout(() => {
      showSlider('next');
    }, timeAutoNext);

    return () => {
      clearTimeout(autoNextRef.current);
    };
  }, []);

  return (
    <div className='flex justify-center items-center md:w-[90%] md:mx-auto'>
      <div className="carousel">
        <div className="list">
        <h6 className='z-[1200] text-5xl flex'><RiCloseCircleFill onClick={()=>navigate(-1)} className='z-[1200] text-5xl text-blue-950 mt-2 ml-2'/></h6>
          {images.map((image, index) => (
            <div
              key={index}
              className={`item ${index === currentSlide ? "active" : ""}`}
              style={{ backgroundImage: `url(${image.imageUrl})` }}
            >
              {/* <div className="content">
                <div className="title">edge</div>
              </div> */}
            </div>
          ))}
        </div>

        <div className="arrows">
          <button className="prev" onClick={handlePrevClick}> &lt; </button>
          <button className="next" onClick={handleNextClick}> &gt; </button>
        </div>

        <div className="timeRunning"></div>
      </div>
    </div>
  );
};

export default Hoc2;