import { React, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Element } from 'react-scroll';
import Header from './components/Header';
import Hero from './components/Hero';
import bg from './assets/css/bg.gif';
import AboutUs from './components/AboutUs';
import Events from './components/Events';
import Registration from './components/Registration';
import OurEvents from './components/OurEvents';
import Faq from './components/Faq/Faq';
import ContactForm from './components/Contact';
import Footer from './components/Footer';
import Gallery from './pages/CardComponent';
import Execom from './components/Execom';
import chairperson from './assets/img/Execom/EVENT/Gautham.jpg';
import vice_chair from './assets/img/Execom/CHAIR_CO/Rhithika M Pradeep.jpg'
import faculty_sponsor from './assets/img/Execom/Advisors/sruthy miss.jpg';
import Execom22 from './components/Execom22';
import Execom23 from './components/Execom23';
import Execom24 from './components/Execom24';
import Map from './components/Map';
import Celestia from './pages/Celestia'
import Edge1 from './pages/Edge1'
import Hoc1 from './pages/Hoc1'
import Renascence from './pages/Renascence'
import Edge_3 from './pages/Edge_3'
import Celestia24 from './pages/Celestia24'
import Hoc2 from './pages/Hoc2'
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const aboutRef = useRef(null);

  return (
    <>
      <Router>
        <ScrollToTop />
        <div
          className='bg-[#013d5fd9] min-h-screen overflow-x-hidden m-0 p-0'
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
          }}
        >
          <Header />
          <Routes>
            <Route
              path="/"
              element={<HomePage aboutRef={aboutRef} />}
            />

            <Route path="/gallery" element={<Gallery />} />
            <Route path="/celestia" element={<Celestia />} />
            <Route path="/edge1" element={<Edge1 />} />
            <Route path="/hoc1" element={<Hoc1 />} />
            <Route path="/renascence" element={<Renascence />} />
            <Route path="/edge_3" element={<Edge_3 />} />
            <Route path="/hoc2" element={<Hoc2 />} />
            <Route path="/events" element={<Events />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/exe1" element={<Execom22 />} />
            <Route path="/exe2" element={<Execom23 />} />
            <Route path="/exe3" element={<Execom24 />} />
            <Route path="/celestia_24" element={<Celestia24/>} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

function HomePage({ aboutRef }) {
  const navigate = useNavigate();

  return (
    <div className='m-0 p-0'>
      <Hero />
      <div ref={aboutRef} className='m-0 p-0'>
        <Element name='aboutSection'>
          <AboutUs />
        </Element>
      </div>
      <section id="events" className='m-0 p-0'>
        <Element name='events'>
          <Events />
        </Element>
      </section>
      <OurEvents className='m-0 p-0' />
      <Faq className='m-0 p-0' />
      <div className='bg-black space-y-10 flex flex-col justify-between'>
        <div className='flex justify-center text-[32px] font-sans text-white pt-10'>
          <h1>EXECOM 24-25</h1>
        </div>
        <div className='flex justify-center flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 pb-12'>
          <Execom img={chairperson} name='Gautham Prasanth' pos='CHAIRPERSON' />
          <Execom img={vice_chair} name='Rhithika M Pradeep' pos='VICE CHAIR' />
          <Execom img={faculty_sponsor} name='Sruthy Suresh' pos='FACULTY SPONSOR' />
        </div>
        <div className='flex justify-center pb-7'>
          <button onClick={() => navigate('/exe3')} className='text-[#5aa5f5] border px-5 py-2 rounded-lg border-white'>
            More
          </button>
        </div>
      </div>
      <Element name='contact'>
        <ContactForm className='m-0 p-0' />
      </Element>
      <Map />
      <Footer className='m-0 p-0' />
    </div>
  );
}

export default App;
