import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { FaAngleDown, FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import { scroller } from 'react-scroll';

function Header({ aboutRef, eventsRef }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [IsScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Disable scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  const handleNavigation = () => {
    navigate('/gallery');
    setIsSidebarOpen(false); // Close sidebar after navigation
  };

  const scrollToSection = (sectionRef, targetPage = '/') => {
    if (location.pathname !== targetPage) {
      navigate(targetPage);
    }
    
    // Wait for the next frame to ensure the DOM has updated
    requestAnimationFrame(() => {
      scroller.scrollTo(sectionRef, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuad',
        offset: -70,
      });
    });

    setIsSidebarOpen(false); // Close sidebar after scrolling
  };

  return (
    <div className='w-11/12 top-0 mx-auto flex justify-between items-center max-h-36 py-4 text-white'>
      {/* Logo */}
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className='w-48'
        src={logo}
        alt="Logo"
      />
      {/* Desktop Navigation */}
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className={`hidden md:flex space-x-10 text-base ${IsScrolled ? 'bg-slate-500' : 'bg-transparent'}`}
      >
        <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} className='cursor-pointer'><Link to="/">Home</Link></motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}
          className='cursor-pointer'
          onClick={() => scrollToSection('about', '/')}
        >
          About
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}
          className='cursor-pointer'
          onClick={() => scrollToSection('events', '/')}
        >
          Events
        </motion.li>
        <motion.li onClick={handleNavigation} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} className='cursor-pointer'>Gallery</motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className='relative cursor-pointer flex items-center'
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          EXECOM
          <FaAngleDown />
          {isDropdownOpen && (
            <motion.ul
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
              className='absolute left-0 mt-36 w-40 backdrop-blur-lg shadow-md rounded-lg'
            >
              <motion.li
                whileHover={{ backgroundColor: '#7076b9' }}
                onClick={() => { navigate('/exe1'); setIsDropdownOpen(false); }}
                className='px-4 py-2 cursor-pointer hover:backdrop-blur-md'
              >
                EXECOM 22-23
              </motion.li>
              <motion.li
                onClick={() => { navigate('/exe2'); setIsDropdownOpen(false); }}
                whileHover={{ backgroundColor: '#7076b9' }}
                className='px-4 py-2 cursor-pointer hover:backdrop-blur-md'
              >
                EXECOM 23-24
              </motion.li>
              <motion.li
                whileHover={{ backgroundColor: '#7076b9' }}
                onClick={() => { navigate('/exe3'); setIsDropdownOpen(false); }}
                className='px-4 py-2 cursor-pointer hover:backdrop-blur-md'
              >
                EXECOM 24-25
              </motion.li>
            </motion.ul>
          )}
        </motion.li>
        <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); scrollToSection('contact') }}>Contact</motion.li>
      </motion.ul>
      {/* Sidebar Button for Small Screens */}
      <button
        className='md:hidden text-2xl'
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FaBars className='mr-5 md:hidden' />
      </button>
      {/* Sidebar for Small Screens */}
      <AnimatePresence>
        {isSidebarOpen && (
          <motion.div
            key="sidebar"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
            className='fixed top-0 right-0 h-full w-64 backdrop-blur-md shadow-md z-50 p-4'
          >
            {/* Close Button */}
            <button
              className='text-2xl absolute top-4 right-4'
              onClick={() => setIsSidebarOpen(false)}
            >
              <FaTimes className='mt-10 mr-5 md:hidden' />
            </button>
            {/* Sidebar Navigation */}
            <motion.ul className='mt-10 flex flex-col space-y-6 text-lg'>
              <motion.li whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); navigate('/'); }}><Link to="/">Home</Link></motion.li>
              <motion.li whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); scrollToSection('about', '/'); }}>About</motion.li>
              <motion.li whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); scrollToSection('events', '/'); }}>Events</motion.li>
              <motion.li whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); handleNavigation(); }}><Link to="/gallery">Gallery</Link></motion.li>
              <motion.li
                whileTap={{ scale: 0.95 }}
                className='cursor-pointer flex items-center'
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                EXECOM <FaAngleDown className='ml-2' />
              </motion.li>
              {isDropdownOpen && (
                <motion.ul
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className='ml-4 space-y-2'
                >
                  <motion.li
                    whileTap={{ scale: 0.95 }}
                    className='cursor-pointer'
                    onClick={() => { setIsSidebarOpen(false); navigate('/exe1'); }}
                  >
                    EXECOM 22-23
                  </motion.li>
                  <motion.li
                    whileTap={{ scale: 0.95 }}
                    className='cursor-pointer'
                    onClick={() => { setIsSidebarOpen(false); navigate('/exe2'); }}
                  >
                    EXECOM 23-24
                  </motion.li>
                  <motion.li
                    whileTap={{ scale: 0.95 }}
                    className='cursor-pointer'
                    onClick={() => { setIsSidebarOpen(false); navigate('/exe3'); }}
                  >
                    EXECOM 24-25
                  </motion.li>
                </motion.ul>
              )}
              <motion.li whileTap={{ scale: 0.95 }} className='cursor-pointer' onClick={() => { setIsSidebarOpen(false); scrollToSection('contact'); }}>Contact</motion.li>
            </motion.ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Header;
