import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Execom from './Execom';
import p1 from '../assets/img/team/chair1.jpg';
import p2 from '../assets/img/Execom/Advisors/sruthy miss.jpg';
import p3 from '../assets/img/team/sreelakshmi.jpeg';
import p4 from '../assets/img/team/Secret1.jpg';
import p5 from '../assets/img/team/treasure.jpeg';
import p6 from '../assets/img/team/Content1.jpg';
import p7 from '../assets/img/team/tech.jpeg';
import p8 from '../assets/img/team/link1.jpg';
import p9 from '../assets/img/team/design1.jpg';
import ContactForm from './Contact';
import Footer from './Footer';

function Execom22() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
    const [IsScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/gallery');
    };

    const textVarient = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            },
        },
    };

    const textVarient1 = {
        hidden: { opacity: 0, x: 100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            },
        },
    };

    const buttonVarient = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.8,
                delay: 0.2,
                ease: "easeInOut",
            },
        },
    };

    return (
        <div>
            <div>
                <div>
                    <div className="w-4/5 mx-auto min-h-[calc(100vh-9rem)] flex flex-col justify-center items-center text-center space-y-5">
                        <motion.h1
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient}
                            className="text-4xl text-white md:text-5xl font-bold mb-4 font-open-sans"
                        >
                            EXECOM 2022-23
                        </motion.h1>
                        <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient1}
                            className="text-lg text-white mb-6 font-serif"
                        >
                            Building the excellence together...
                        </motion.p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center bg-black space-y-8 pb-16">
                <h1 className="text-white text-4xl pt-7 pb-5">EXECOM 22-23</h1>

                <div className="w-screen justify-center space-y-2 md:flex md:space-x-5">
                    <Execom img={p1} name="Amarjith Raj" pos="CHAIRPERSON" link="https://www.linkedin.com/in/amarjith-raj-b2822a196/"/>
                    <Execom img={p2} name="Sruthy Suresh" pos="FACULTY SPONSOR" link="http://www.linkedin.com/in/sruthy-suresh-87ba32243"/>
                    <Execom img={p3} name="Sreelakshmi Vinod" pos="VICE CHAIR" link="https://www.linkedin.com/in/sreelakshmi-vinod-b227471b8"/>
                </div>

                <div className="w-screen justify-center space-y-2 md:flex md:space-x-5">
                    <Execom img={p4} name="P V Keerthana" pos="SECRETARY" link="https://www.linkedin.com/in/p-v-keerthana-25b5b3257"/>
                    <Execom img={p5} name="Srinand S" pos="TREASURER" link=""/>
                    <Execom img={p6} name="Nandana Sreekumar" pos="CONTENT HEAD" link="https://www.linkedin.com/in/nandana-sreekumar-nair-55076a245"/>
                </div>

                <div className="w-screen justify-center space-y-2 md:flex md:space-x-5">
                    <Execom img={p7} name="Gautham Prasanth" pos="TECH HEAD" link="https://www.linkedin.com/in/gautham-prasanth-3276a5251/"/>
                    <Execom img={p8} name="Smingle Simon" pos="LINK REP" link="https://www.linkedin.com/in/smingle-simon-831604257"/>
                    <Execom img={p9} name="Isac John Eralil" pos="DESIGN HEAD" link="https://www.linkedin.com/in/isac-john-eralil-9b48a21a3"/>
                </div>
            </div>

            <ContactForm />
            <Footer />
        </div>
    );
}

export default Execom22;
