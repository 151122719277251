import React from 'react';
import './styles.css';
import p1 from '../assets/img/celestia/poster.jpg'
import p2 from '../assets/img/edge/poster.jpg'
import p3 from '../assets/img/HoC/newposter.png'
import p4 from '../assets/img/Renescence_23/3.jpg'
import p5 from '../assets/img/edge_posters.jpg'
import p6 from '../assets/img/hourofcode23.jpg'
import p8 from '../assets/img/celestia/celestia_24.jpg'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
const CardComponent = () => {
  const textVarient = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1, x: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  const textVarient1 = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1, x: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };
  const navigate = useNavigate()
  return (
    <div className='mb-48 flex flex-col pl-10 mr-12 md:w-4/5 md:mx-auto'>
      <div>
        <div>
          <div className="w-4/5 mx-auto min-h-[calc(100vh-9rem)] flex flex-col justify-center items-center text-center space-y-5">
            <motion.h1
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5 }}
              variants={textVarient}
              className="text-2xl text-white md:text-5xl font-bold mb-4 font-open-sans"
            >
              EXPLORE OUR EVENT MEMORIES
            </motion.h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="card__container">
          <article className="card__article">
            <img onClick={() => navigate('/celestia')} src={p1} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">CELESTIA 23</h2>
              <a className="card__button" onClick={() => navigate('/celestia')}>Explore Event Gallery</a>
            </div>
          </article>

          <article className="card__article">
            <img onClick={() => navigate('/edge1')} src={p2} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">EDGE +</h2>
              <a className="card__button" onClick={() => navigate('/edge1')}>Explore Event Gallery</a>
            </div>
          </article>
          <article className="card__article">
            <img onClick={() => navigate('/hoc2')} src={p3} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">HOUR OF CODE 22</h2>
              <a className="card__button" onClick={() => navigate('/hoc2')}>Explore Event Gallery</a>
            </div>
          </article>
          <article className="card__article">
            <img onClick={() => navigate('/renascence')} src={p4} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">RENASCENCE</h2>
              <a className="card__button" onClick={() => navigate('/renascence')}>Explore Event Gallery</a>
            </div>
          </article>
          <article className="card__article">
            <img onClick={() => navigate('/edge_3')} src={p5} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">EDGE+ 3.0</h2>
              <a className="card__button" onClick={() => navigate('/edge_3')}>Explore Event Gallery</a>
            </div>
          </article>
          <article className="card__article">
            <img onClick={() => navigate('/hoc1')} src={p6} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">HOUR OF CODE 23</h2>
              <a className="card__button" onClick={() => navigate('/hoc1')}>Explore Event Gallery</a>
            </div>
          </article>
          <article className="card__article">
            <img onClick={() => navigate('/celestia_24')} src={p8} alt="Image failed to load" className="card__img" />
            <div className="card__data">
              <h2 className="card__title">CELESTIA 24</h2>
              <a className="card__button" onClick={() => navigate('/celestia_24')}>Explore Event Gallery</a>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;