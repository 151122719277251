import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqs = [
        {
            question: "What is ACM ?",
            answer: "ACM Student Chapter is the international Association for Computing Machinery's student society which provides opportunities to students for networking, learn together and share their knowledge. Its main focus is on building and developing members' passion for computer science."
        },
        {
            question: "Why ACM ?",
            answer: "ACM strengthens the computing profession's collective voice through strong leadership, promotion of the highest standards, and recognition of technical ."
        },
        {
            question: "What are the reasons to join ACM ?",
            answer: "There are many reasons to join ACM. When you become a member, you become a part of the dynamic changes that are transforming our world by helping to shape the future of computing. ACM provides the tools and resources to help get you there by advancing your career and enriching your knowledge with life-long learning resources. The members of chapters are eligible for various benefits such as coding competitions, technical talks and mentoring sessions by experienced professionals."
        },
    ];

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <article id="the-article" className="bg-[#212529] p-6">
            <div className="mx-auto max-w-6xl">
                <div className="flex flex-col md:flex-row md:justify-between">
                    <div className="md:w-1/3 p-4 text-sm">
                        <div className="sticky inset-x-0 top-0 py-12">
                            <div className="text-3xl text-[#5aa5f5] mb-8">Frequently Asked Questions</div>
                            <div className="text-xs text-slate-400">See our FAQ for more details</div>
                            <input
                                type="search"
                                placeholder="Search"
                                className="bg-white w-full h-10 px-5 rounded-full text-sm focus:outline-none mt-8"
                            />
                        </div>
                    </div>
                    <div className="md:w-2/3 py-12">
                        {faqs.map((faq, index) => (
                            <div key={index} className="item px-6 py-6">
                                <button
                                    className="flex items-center justify-between w-full text-left"
                                    onClick={() => toggleAnswer(index)}
                                >
                                    <h4 className={openIndex === index ? 'text-[#7071b9] font-medium' : 'text-white'}>
                                        {faq.question}
                                    </h4>
                                    {openIndex === index ? <FaMinus className="w-5 h-5 text-gray-500" /> : <FaPlus className="w-5 h-5 text-gray-500" />}
                                </button>
                                <div className={`mt-3 transition-all duration-1000 ease-in-out ${openIndex === index ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                                    <p className={`text-slate-300 transition-opacity duration-500 ease-in-out ${openIndex === index ? 'opacity-100' : 'opacity-0'}`}>
                                        {faq.answer}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default Faq;
