import React from 'react';
import background from '../assets/img/bac1.jpeg';

function Registration() {
    return (
        <div className='space-y-5 bg-[#212529] pb-8' >
            <div
                className='w-4/5 mx-auto flex flex-col justify-center items-center pt-10 space-y-5'

            >
                <h1 className='text-white text-3xl'>ACM Memberships</h1>
                <p className='text-white'>!! Registration Closed !!</p>
            </div>
        </div>
    );
}

export default Registration;
