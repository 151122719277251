import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';

import { FaAngleDown, FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Gallery from '../pages/CardComponent'
import { useState } from 'react';
import Execom from './Execom';
import p1 from '../assets/img/Execom24/ACM Core/ACM Core/20240917_150801.jpg'
import p2 from '../assets/img/Execom/CHAIR_CO/Rhithika M Pradeep.jpg'
import p3 from '../assets/img/Execom24/ACM Core/ACM Core/Hisham Haskar(1).jpg'
import p3_1 from '../assets/img/Execom24/ACM Core/ACM Core/IMG_2099.jpg'
import p4 from '../assets/img/Execom24/Women Cell/Women Cell/IMG-20240811-WA0113.jpg'
import p5 from '../assets/img/Execom24/Women Cell/Women Cell/nandanasreekumar.jpg'
import p6 from '../assets/img/Execom24/Women Cell/Women Cell/IMG-20240818-WA0126.jpg'
import p7 from '../assets/img/Execom24/Advisory Board/Advisory Board/IMG-20240312-WA0084.jpg'
import p8 from '../assets/img/Execom24/Advisory Board/Advisory Board/joelboby.jpg'
import p9 from '../assets/img/Execom24/Advisory Board/Advisory Board/aneeka.jpg'
import p9_1 from '../assets/img/Execom24/Advisory Board/Advisory Board/sreelakshmi.jpg'
import p10 from '../assets/img/Execom24/Advisory Board/Advisory Board/achyuth.jpg'
import p11 from '../assets/img/Execom24/Advisory Board/Advisory Board/joel.jpg'
import p12 from '../assets/img/Execom24/Advisory Board/Advisory Board/IMG-20240312-WA0084.jpg'
import p13 from '../assets/img/Execom24/Advisory Board/Advisory Board/srinads.jpg'
import p14 from '../assets/img/Execom24/Advisory Board/Advisory Board/isacjohn.jpg'
import p15 from '../assets/img/Execom24/Content Team/Content Team/Harinandana K Biju.jpg'
import p16 from '../assets/img/Execom24/Content Team/Content Team/Daniel Geo.jpg'
import p17 from '../assets/img/Execom24/Design Team/Design Team/Rahul KJ.jpg'
import p18 from '../assets/img/Execom24/Design Team/Design Team/sharika.jpeg'
import p19 from '../assets/img/Execom24/Event Team/Event Team/Gowri s.jpg'
import p20 from '../assets/img/Execom24/Event Team/Event Team/NAFIH.jpg'
import p21 from '../assets/img/Execom24/Event Team/Event Team/Anupama K J.jpg'
import p22 from '../assets/img/Execom24/Tech Team/Tech Team/Diya KDileep.jpg'
import p23 from '../assets/img/Execom24/Tech Team/Tech Team/Sidharth CJ_.jpg'
import p23_1 from '../assets/img/Execom24/Tech Team/Tech Team/grigary.png'
import p25 from '../assets/img/Execom24/PR Team/PR Team/Ron Binoy Mechery.jpg'
import p26 from '../assets/img/Execom24/PR Team/PR Team/Devananda S/Devananda S.jpg'
import p27 from '../assets/img/Execom24/PR Team/PR Team/Devananda Devakumar.png'
import p28 from '../assets/img/Execom24/Membership Dev Team/Membership Dev Team/Wilson Nevin.jpg'
import p29 from '../assets/img/Execom24/Membership Dev Team/Membership Dev Team/Hisham Ahamed T P.jpg'
import p30 from '../assets/img/Execom24/Media Team/Media Team/Hamad_.jpg'
import p31 from '../assets/img/Execom24/Media Team/Media Team/Samiksha Sreekumar_.jpg'

import ContactForm from './Contact';
import Footer from './Footer';
function Execom23() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
    const [IsScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = () => {
        // Navigate to the '/gallery' route
        navigate('/gallery');
    };
    const textVarient = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            }
        }
    }
    const textVarient1 = {
        hidden: { opacity: 0, x: 100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0,
                ease: "easeInOut",
            }
        }
    }
    const buttonVarient = {
        hidden: { opacity: 1, scale: 0.95 }, // Start scaled down
        visible: {
            opacity: 1,
            scale: 1, // Scale to normal size
            transition: {
                duration: 0.35,
                delay: 0.2,
                ease: "easeInOut",
            }
        }
    };
    
    return (
        <div>
            <div>
                
                <div>
                    <div className='w-4/5 mx-auto min-h-[calc(100vh-9rem)] flex flex-col justify-center items-center text-center space-y-5' id='Home'>
                        <motion.h1
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient}
                            className='text-4xl md:text-5xl font-bold mb-4 font-open-sans text-white'>EXECOM 2024-25</motion.h1>
                        <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient1}
                            className='text-lg mb-6 font-serif text-white'>Building the excellence together...</motion.p>
                        {/* <motion.button
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={buttonVarient}
                            className='px-6 py-3 bg-transparent border border-white text-blue-500 text-white rounded-full font-serif'>Get Started</motion.button> */}
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center bg-black space-y-8 pb-16'>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>CORE EXECOM</h1>
                <div className='space-y-5'>
                    <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p1} name='Gautham Prasanth' pos='Chairperson' link='https://www.linkedin.com/in/gautham-prasanth-3276a5251/'/>
                        <Execom img={p2} name='Rhithika M Pradeep' pos='Vice-Chair' link='https://www.linkedin.com/in/rhithika-pradeep-8a590a256'/>
                        <Execom img={p3} name='Hisham Hasker' pos='Secretary' link="https://www.linkedin.com/in/hisham-haskar-047b862b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"/>
                        <Execom img={p3_1} name='Faadil Mohammed' pos='Treasurer' link="https://www.linkedin.com/in/faadil-mohammed-07b9461b7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"/>
                    </motion.div>
                </div>
                {/* <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'></div> */}
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>ACM WOMEN CELL</h1>
                <div className='space-y-5'>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.7}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p5} name='Nandana Sreekumar' pos='Chairperson' link='https://www.linkedin.com/in/nandana-sreekumar-nair-55076a245/'/>
                        <Execom img={p6} name='Ann Maria Tommichan' pos='Vice-Chair' link='https://www.linkedin.com/in/ann-maria-tomichan-1a635b255/'/>
                        <Execom img={p4} name='Sreelekshmi' pos='Secretary' link='https://linkedin.com/in/sreelakshmi-p-4b4678277'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>ACM ADVISORY BOARD</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p8} name='Joel Bobby' link='https://www.linkedin.com/in/joel-boby-m-1b3431190?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                        <Execom img={p9} name='Aneeka Geo' link='https://www.linkedin.com/in/aneeka-geo'/>
                        <Execom img={p10} name='Achyuth Anilkumar' link='https://www.linkedin.com/in/achyuth-anil/'/>
                        <Execom img={p11} name='Joel Nickson' link='https://www.linkedin.com/in/joel-nickson'/>
                    </motion.div>
                    <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        
                        <Execom img={p12} name='Smingle Simon'/>
                        <Execom img={p13} name='Srinand S' link='https://www.linkedin.com/in/srinand-s-6b3633294'/>
                        <Execom img={p14} name='Isac John' link='https://www.linkedin.com/in/isac-john-eralil-9b48a21a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'/>
                        <Execom img={p9_1} name='Sreelakshmi' link='https://www.linkedin.com/in/sreelakshmivin'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>CONTENT TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p15} name='Harinandana' pos='Lead' link='https://www.linkedin.com/in/harinandana-k-biju-037b07263'/>
                        <Execom img={p16} name='Daniel Geo' pos='Co Lead' link='https://www.linkedin.com/in/daniel-geo-081174294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>DESIGN TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p17} name='Rahul K J' pos='Lead' link='https://www.linkedin.com/in/rahul-kj-170824256?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'/>
                        <Execom img={p18} name='Sharika' pos='Co Lead' link='https://www.linkedin.com/in/sharika-s-6982232a7'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>EVENT TEAM</h1>
                <div>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p19} name='Gowri S' pos='Coordinator' link='https://www.linkedin.com/in/gauri-s-9a2907256/'/>
                        <Execom img={p20} name='Muhammed Nafih' pos='Coordinator' link='https://www.linkedin.com/in/muhammed-nafih-k-p-b172281a8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'/>
                        <Execom img={p21} name='Anupama' pos='Coordinator' link='https://www.linkedin.com/in/anupama-k-j-516329255'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>TECH TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p22} name='Diya K Dileep' pos='Coordinator' link='https://www.linkedin.com/in/diya-k-dileep'/>
                        <Execom img={p23} name='Sidharth' pos='Coordinator' link='https://www.linkedin.com/in/sidharth-cj-26a194294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>                     
                        <Execom img={p23_1} name='Grigary M Varkey' pos='Web Master' link='https://www.linkedin.com/in/grigarymvarkey'/>
                    </motion.div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>PR TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p25} name='Ron Binoy' pos='Lead' link='https://www.linkedin.com/in/ron-binoy-mechery-0200b2255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                        <Execom img={p26} name='Devananda S' pos='Co Lead' link='https://www.linkedin.com/in/devananda-s-11b812269/'/>
                        <Execom img={p27} name='Devananda Devakumar' pos='Co Lead' link='https://www.linkedin.com/in/devananda-devakumar-92b7702b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                    </motion.div>
                </div>

                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>MEMBERSHIP DEV TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p28} name='Wilson Nevin' pos='Coordinator' link='https://www.linkedin.com/in/wilson-nevin-727459247?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                        <Execom img={p29} name='Hisham Ahammed TP' pos='Coordinator' link='https://www.linkedin.com/in/hisham-ahamed-6334b1255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'/>
                    </motion.div>
                </div>

                <h1 className='text-white text-4xl pt-7 pb-5 text-center'>MEDIA TEAM</h1>
                <div className='space-y-5'>
                <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{amount:0.5}}
                    variants={buttonVarient} className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p30} name="Hamad Mishal" pos='Lead' link='https://www.linkedin.com/in/hamad-mishal-57828a255'/>
                        <Execom img={p31} name='Samiksha' pos='Co Lead' link='https://www.linkedin.com/in/muhammed-nafih-k-p-b172281a8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'/>
                    </motion.div>
                </div>
            </div>
            <ContactForm />
            <Footer />
        </div>
    )
}
export default Execom23