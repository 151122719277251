import React from 'react';
import edge1 from '../assets/img/edge_posters.jpg';
import hoc from '../assets/img/hourofcode23.jpg';
import celestia from '../assets/img/celestia/poster.jpg';
import renascence from '../assets/img/Renescence_23/3.jpg';
import hoc2 from '../assets/img/HoC/newposter.png';
import { useNavigate } from 'react-router-dom';
function OurEvents() {
    const navigate = useNavigate();

  const handleNavigation = () => {
    // Navigate to the '/gallery' route
    navigate('/gallery');
  };
    const events = [
        {
            title: "Description One",
            text: "Tech-fun event for all students including workshop on Chatbot using Dialogflow, Cultural night and a lot of fun games.",
            imageUrl: edge1
        },
        {
            title: "Description Two",
            text: "Workshop on basics of Python and HTML for students of Gov.Higher Secondary School, Mookanoor conducted by ACM members.",
            imageUrl: hoc
        },
        {
            title: "Description Three",
            text: "Annual inter-collegiate quiz competition with prizes worth rupees 1.5K for first place and 1K for second place.",
            imageUrl: celestia
        },
        {
            title: "Description Four",
            text: "One day Tech-fun event for first-year students including workshops on GitHub, Git and Linux basics along with TechZap, a rapid-fire quiz competition.",
            imageUrl: renascence
        },
        {
            title: "Description Five",
            text: "Workshop on basics of Python and HTML for students of Gov.Higher Secondary School, Mookanoor conducted by ACM members.",
            imageUrl: hoc2
        },
    ];

    return (
        <div className='bg-black'>
            <h1 className="text-center text-[32px] font-sans text-white pt-14">Our Events</h1>
            <section className="flex flex-col lg:h-screen justify-center items-center overflow-auto">

                {/* Medium screens and above layout */}
                <div className="hidden md:flex flex-wrap mx-auto md:flex-nowrap mt-6 border-t pt-12">
                    {events.map((event, index) => (
                        <div key={index} className="relative flex flex-col items-start m-1 transition ease-in-out duration-500 delay-150 transform md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0">
                            <article className="mx-auto rounded-3xl overflow-hidden bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative transform duration-500 group" style={{ backgroundImage: `url(${event.imageUrl})` }}>
                                <div className="relative h-full group-hover:bg-opacity-0 min-h-150 flex flex-wrap flex-col pt-[30rem] transform duration-500">
                                    <div className="group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col">
                                        <p className="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500">{event.text}</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    ))}
                </div>

                {/* Below medium screens layout */}
                <div className="md:hidden flex flex-col items-center mt-6 border-t pt-12">
                    {events.map((event, index) => (
                        <div key={index} className="relative flex flex-col items-center m-1 transition ease-in-out duration-500 delay-150 transform w-full max-w-xs mb-4">
                            <article className="mx-auto rounded-3xl overflow-hidden bg-cover ring-2 ring-inset ring-white/50 bg-center relative transform duration-500 group" style={{ backgroundImage: `url(${event.imageUrl})`, minHeight: '250px' }}>
                                <div className="relative h-full group-hover:bg-opacity-0 flex flex-wrap flex-col pt-8 transform duration-500">
                                    <div className="group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-4 lg:p-6 h-full justify-end flex flex-col">
                                        <p className="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500">{event.text}</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    ))}
                </div>

                <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center p-2">

                </div>
            </section>
            <div className='flex justify-center  md:pt-1 pb-14'>
                <button
                onClick={handleNavigation}
                className='text-[#5aa5f5] border border-white px-4 py-2 rounded-lg'>Explore</button>
            </div>
        </div>
    );
}

export default OurEvents;
