import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { FaTwitter, FaLinkedin, FaFacebookF, FaInstagram, FaEnvelope, FaPhone } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });

  const [isSuccess, setIsSuccess] = React.useState(false);
  const [Message, setMessage] = React.useState("");

  const userName = useWatch({
    control,
    name: "name",
    defaultValue: "Someone"
  });

  useEffect(() => {
    setValue("subject", `${userName} sent a message from Website`);
  }, [userName, setValue]);

  const onSubmit = async (data, e) => {
    console.log(data);
    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setMessage(json.message);
          e.target.reset();
          reset();
        } else {
          setIsSuccess(false);
          setMessage(json.message);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        console.log(error);
      });
  };

  return (
    <div className="bg-[#212529] text-white pb-10">
      <div className="w-4/5 mx-auto flex flex-col md:w-4/5 mx-auto px-4 md:px-0 pt-10">
        <div className="text-center mb-2 space-y-7">
          <h1 className="text-3xl  font-lato">Contact Us</h1>
          <p className="text-base">Empowering minds, advancing technology, and shaping the future of computing.</p>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between items-center space-y-8 lg:space-y-0 lg:space-x-10">
          <div className="lg:w-1/3">
            <h1 className=" font-semibold text-[#7076b9] text-4xl font-lato">ACM</h1>
            <p className="mt-4">
              "ACM's unwavering commitment to advancing computing as a science and profession propels innovation, empowers individuals, and shapes the future of technology."
            </p>
            <div className="flex space-x-4 mt-4">
              {/* Add hover Effect */}
              <FaLinkedin className="text-2xl hover:text-[#7076b9] transition duration-500" />
              <FaTwitter className="text-2xl hover:text-[#7076b9] transition duration-500" />
              <FaFacebookF className="text-2xl hover:text-[#7076b9] transition duration-500" />
              <FaInstagram href="https://www.instagram.com/acm_fisat/" className="text-2xl hover:text-[#7076b9] transition duration-500" />
            </div>
          </div>

          <div className="lg:w-1/3">
            <ul className="space-y-7">
              <li className="flex items-center space-x-2">
              <GrLocation  className="text-5xl text-[#7076b9]" />
                <p>Federal Institute of Science and Technology-Hormis Nagar, Mookkannoor, Angamaly, Kerala 683577</p>
              </li>
              <li className="flex items-center space-x-2">
                <FaEnvelope className="text-2xl text-[#7076b9]" />
                <p>acm@fisat.ac.in</p>
              </li>
              <li className="flex items-center space-x-2">
                <FaPhone className="text-2xl text-[#7076b9]" />
                <p>Achyuth: +91 8137997339</p>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-1/3 bg-[#212529] text-black rounded-md p-6">
            {!isSubmitSuccessful && (
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <input
                  type="hidden"
                  value="5d1bc690-3d00-4d11-9ead-964ac7c5059e"
                  {...register("access_key")}
                />
                <input
                  type="hidden"
                  {...register("subject")}
                />
                <input
                  type="hidden"
                  value="Mission Control"
                  {...register("from_name")}
                />
                <input
                  type="checkbox"
                  id=""
                  className="hidden"
                  style={{ display: "none" }}
                  {...register("botcheck")}
                />

                <div>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className={`w-full px-4 py-3 border-2 rounded-md outline-none focus:ring-4 ${errors.name ? "border-red-600 focus:border-red-600 ring-red-100" : "border-gray-300 focus:border-indigo-600 ring-indigo-100"}`}
                    {...register("name", {
                      required: "Full name is required",
                      maxLength: 80,
                    })}
                  />
                  {errors.name && (
                    <div className="mt-1 text-red-600">
                      <small>{errors.name.message}</small>
                    </div>
                  )}
                </div>

                <div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className={`w-full px-4 py-3 border-2 rounded-md outline-none focus:ring-4 ${errors.email ? "border-red-600 focus:border-red-600 ring-red-100" : "border-gray-300 focus:border-indigo-600 ring-indigo-100"}`}
                    {...register("email", {
                      required: "Enter your email",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <div className="mt-1 text-red-600">
                      <small>{errors.email.message}</small>
                    </div>
                  )}
                </div>

                <div>
                  <textarea
                    placeholder="Your Message"
                    className={`w-full px-4 py-3 border-2 text-black rounded-md outline-none h-36 focus:ring-4 ${errors.message ? "border-red-600 focus:border-red-600 ring-red-100" : "border-gray-300 focus:border-indigo-600 ring-indigo-100"}`}
                    {...register("message", { required: "Enter your Message" })}
                  />
                  {errors.message && (
                    <div className="mt-1 text-red-600">
                      <small>{errors.message.message}</small>
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full py-4 text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-indigo-200">
                  {isSubmitting ? (
                    <svg
                      className="w-5 h-5 mx-auto text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </form>
            )}

            {isSubmitSuccessful && isSuccess && (
              <div className="text-center text-green-500">
                <h3 className="text-2xl py-5">Your message has been successfully submitted.</h3>
                <p>{Message}</p>
                <button
                  className="mt-6 text-indigo-600"
                  onClick={() => reset()}
                >
                  Go back
                </button>
              </div>
            )}

            {isSubmitSuccessful && !isSuccess && (
              <div className="text-center text-red-500">
                <h3 className="text-2xl py-5">Oops, Something went wrong!</h3>
                <p>{Message}</p>
                <button
                  className="mt-5"
                  onClick={() => reset()}
                >
                  Try Again
                </button>
              </div>
            )}
          </div>
        </div>

        <p className="text-center text-sm mt-8">
          <a
            href="https://web3forms.com/"
            target="_blank"
            rel="noopener"
            className="text-indigo-500"
          >
          </a>
        </p>
      </div>
    </div>
  );
}
