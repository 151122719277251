import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { FaAngleDown, FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Gallery from '../pages/CardComponent'
import { useState } from 'react';
import Execom from './Execom';
import p1 from '../assets/img/Execom/CHAIR_CO/Achyath.png'
import p2 from '../assets/img/Execom/CHAIR_CO/Sminglesimon.jpg'
import p3 from '../assets/img/Execom/Advisors/sruthy miss.jpg'
import p4 from '../assets/img/Execom/CHAIR_CO/Nandana.png'
import p5 from '../assets/img/Execom/CHAIR_CO/Rhithika M Pradeep.jpg'
import p6 from '../assets/img/Execom/CHAIR_CO/hani nazer.png'
import p7 from '../assets/img/Execom/Advisors/Aneeka Geo.jpg'
import p8 from '../assets/img/Execom/Advisors/Copy of amar.jpg'
import p9 from '../assets/img/Execom/Advisors/joelbobym.jpg'
import p10 from '../assets/img/Execom/Advisors/Joel Nickson.jpg'
import p11 from '../assets/img/Execom/Advisors/Naveen B Jacob.jpg'
import p12 from '../assets/img/Execom/TUTOR/AparnnaK.jpg'
import p13 from '../assets/img/Execom/TUTOR/NIJAI_.jpg'
import p14 from '../assets/img/Execom/TUTOR/Srinand S.jpg'
import p15 from '../assets/img/Execom/TECH/Diya K Dileep_.jpg'
import p16 from '../assets/img/Execom/TECH/Ann Maria Tomichan_.jpg'
import p17 from '../assets/img/Execom/TECH/Sidharth c j (1).jpg'
import p18 from '../assets/img/Execom/TECH/Sreelakshmi p.jpg'
import p19 from '../assets/img/Execom/TECH/Vyshnav Rajesh Nair .jpg'
import p20 from '../assets/img/Execom/DESIGN/Hariprasad.jpg'
import p21 from '../assets/img/Execom/DESIGN/Hisham Haskar.jpg'
import p22 from '../assets/img/Execom/DESIGN/Jude Abi Pynadath.jpg'
import p23 from '../assets/img/Execom/DESIGN/MUHAMMED NAFIH K P.jpg'
import p24 from '../assets/img/Execom/CONTENT/Naeema sameer.jpg'
import p25 from '../assets/img/Execom/CONTENT/minwa rahiman.jpeg'
import p26 from '../assets/img/Execom/CONTENT/navya sreekumar.jpeg'
import p27 from '../assets/img/Execom/EVENT/Gautham.jpg'
import p28 from '../assets/img/Execom/EVENT/IMG_20230906_122350_070.jpg'
import p29 from '../assets/img/Execom/EVENT/Fathimath suhura.jpg'
import p30 from '../assets/img/Execom/EVENT/Gauri.jpg'
import p31 from '../assets/img/Execom/EVENT/karthik prasad.jpeg'
import p32 from '../assets/img/Execom/MEDIA/Isac.jpg'
import p33 from '../assets/img/Execom/MEDIA/Abin Jacob.jpg'
import p34 from '../assets/img/Execom/MEDIA/Hamad.jpg'
import p35 from '../assets/img/Execom/MEDIA/Vishnuhari2.jpg'
import ContactForm from './Contact';
import Footer from './Footer';
function Execom23() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
    const [IsScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = () => {
        // Navigate to the '/gallery' route
        navigate('/gallery');
    };
    const textVarient = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            }
        }
    }
    const textVarient1 = {
        hidden: { opacity: 0, x: 100 },
        visible: {
            opacity: 1, x: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            }
        }
    }
    const buttonVarient = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.8,
                delay: 0.2,
                ease: "easeInOut",
            }
        }
    }
    return (
        <div>
            <div>
                
                <div>
                    <div className='w-4/5 mx-auto min-h-[calc(100vh-9rem)] flex flex-col justify-center items-center text-center space-y-5' id='Home'>
                        <motion.h1
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient}
                            className='text-4xl md:text-5xl font-bold mb-4 font-open-sans text-white'>EXECOM 2023-24</motion.h1>
                        <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={textVarient1}
                            className='text-lg mb-6 font-serif text-white'>Building the excellence together...</motion.p>
                        {/* <motion.button
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            variants={buttonVarient}
                            className='px-6 py-3 bg-transparent border border-white text-blue-500 text-white rounded-full font-serif'>Get Started</motion.button> */}
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center bg-black space-y-8 pb-16'>
                <h1 className='text-white text-4xl pt-7 pb-5'>CORE EXECOM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p1} name='Achyuth Anil' pos='CHAIRPERSON' link="https://www.linkedin.com/in/achyuth-anil"/>
                        <Execom img={p2} name='Smingle Simon' pos='VICE CHAIR' link="https://in.linkedin.com/in/smingle-simon-831604257"/>
                        <Execom img={p3} name='Sruthy Suresh' pos='FACULITY SPONSOR' link="http://www.linkedin.com/in/sruthy-suresh-87ba32243"/>
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p4} name='Nandana Sreekumar' pos='SECRETARY' link="https://www.linkedin.com/in/nandana-sreekumar-nair-55076a245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"/>
                        <Execom img={p5} name='Rhithika M Pradeep' pos='TREASURER' link="https://www.linkedin.com/in/rhithika-pradeep-8a590a256"/>
                        <Execom img={p6} name='Hani Nazer' pos='LINK REPRESENTATIVE' link="https://www.linkedin.com/in/hani-nazer-179bb722a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>ADVISORY TEAM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p7} name='Aneeka Geo' link="https://www.linkedin.com/in/aneeka-geo"/>
                        <Execom img={p8} name='Amarjith Raj' link="https://www.linkedin.com/in/amarjith-raj-b2822a196/"/>
                        <Execom img={p9} name='Joel Boby' link="https://www.linkedin.com/in/joel-boby-m-1b3431190?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BMX8J5hhESMC%2F3uZq%2F9yPhQ%3D%3D"/>
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p10} name='Joel Nickson' link="linkedin.com/in/joel-nickson"/>
                        <Execom img={p11} name='Naveen B Jacob' link="https://www.linkedin.com/in/naveenbjacob"/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>TUTOR TEAM</h1>
                <div>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p12} name='Aparnna K' link=""/>
                        <Execom img={p13} name='Nijai' link=""/>
                        <Execom img={p14} name='Srinand S' link="https://www.linkedin.com/in/srinand-s-6b3633294"/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>TECH TEAM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p15} name='Diya K Dileep' pos='TECH HEAD' link="http://www.linkedin.com/in/diya-k-dileep"/>
                        <Execom img={p16} name='Ann Maria Thomichan' pos='TEAM MEMBER' link=""/>
                        <Execom img={p17} name='Sidarth' pos='TEAM MEMBER' link='https://www.linkedin.com/in/sidharth-cj-26a194294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'/>
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p18} name='Sreelakshmi' pos='TEAM MEMBER' link=""/>
                        <Execom img={p19} name='Vyshnav Nair' pos='TEAM MEMBER' link=""/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>DESIGN TEAM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p20} name='Hariprasad' pos='DESIGN HEAD' link="https://www.linkedin.com/in/hariprasad-r-a8a962257"/>
                        <Execom img={p21} name='Hisham Haskar' pos='TEAM MEMBER' link=""/>
                        <Execom img={p22} name='Jude Abi Pynadath' pos='TEAM MEMBER' link="https://www.linkedin.com/in/jude-abi-443368215?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"/>
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p23} name='Muhammed Nafih K P' pos='TEAM MEMBER' link="https://www.linkedin.com/in/muhammed-nafih-k-p-b172281a8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>CONTENT TEAM</h1>
                <div>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p24} name='Naeema Sameer' pos='CONTENT HEAD' link=""/>
                        <Execom img={p25} name='Minwa Rahiman' pos='TEAM MEMBER' link=""/>
                        <Execom img={p26} name='Navya Sreekumar' pos='TEAM MEMBER' link=""/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>EVENT TEAM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p27} name='Gautham Prasanth' pos='EVENT HEAD' link="https://www.linkedin.com/in/gautham-prasanth-3276a5251/"/>
                        <Execom img={p28} name='Anupama' pos='TEAM MEMBER' link=""/>
                        <Execom img={p29} name='Fathimath Suhura' pos='TEAM MEMBER' link=""/>
                        
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                    <Execom img={p30} name='Gauri' pos='TEAM MEMBER' link=""/>
                        <Execom img={p31} name='Karthik' pos='TEAM MEMBER' link=""/>
                    </div>
                </div>
                <h1 className='text-white text-4xl pt-7 pb-5'>MEDIA TEAM</h1>
                <div className='space-y-5'>
                <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p32} name='Isac John' pos='MEDIA HEAD' link="https://www.linkedin.com/in/isac-john-eralil-9b48a21a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"/>
                        <Execom img={p33} name='Abin Jacob' pos='TEAM MEMBER' link=""/>
                        <Execom img={p34} name='Hamad' pos='TEAM MEMBER' link=""/>
                    </div>
                    <div className='w-screen justify-center space-y-2 md:flex md:space-x-5'>
                        <Execom img={p35} name='Vishnuhari' pos='TEAM MEMBER' link=""/>
                    </div>
                </div>
            </div>
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Execom23
